import React, { Component } from 'react';
import { Link } from 'gatsby';

// Components
import GetStartedButton from '../../components/GetStartedButton';
import MobileMenuButton from '../../components/MobileMenuButton';

// Assets
import Logo from '../../images/white_logo_transparent.png';

// Styles
import './Landing.styles.scss';

export default class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inView: true
    };
  }

  componentDidMount() {
    this.landingIsInView();
  }

  componentDidUpdate() {
    this.landingIsInView();
  }

  landingIsInView = () => {
    if (
      this.state.inView &&
      this.props.scrollY >= this.page.offsetHeight
    ) {
      this.setState({
        inView: false
      }, () => {
        this.props.showDropdown();
      });
    }

    if (
      !this.state.inView &&
      this.props.scrollY < this.page.offsetHeight
    ) {
      this.setState({
        inView: true
      }, () => {
        this.props.hideDropdown();
      });
    }
  }


  render() {
    return (
      <section>
        <div className="landing" ref={(elem) => this.page = elem}>
          <div className="landing__mobile-topper">
            <nav className="landing__nav">
              <span className="landing__nav-left">
                <img 
                  className="landing__logo"
                  src={Logo} 
                  alt="Transferable"
                />
              </span>
              <span className="landing__nav-right">
                <span className="landing__nav-link">
                  <Link to="/about">
                    About
                  </Link>
                </span>
                <span className="landing__nav-link">
                  <Link to="/public-benefit">
                    Public Benefit
                  </Link>
                  {/*
                  <div className="dropdown">
                  <span className="dropdown__menu-item">
                    About Us
                  </span>
                  <span className="dropdown__menu-item">
                    Public Benefit
                  </span>
                </div>
                */}
                </span>
                <span className="landing__nav-link">
                  <Link to="/blog">
                    Blog
                  </Link>
                </span>
                <span className="landing__nav-link">
                  <Link to="/resources">
                    Resources
                  </Link>
                </span>
                <span className="landing__nav-link">
                  <Link to="/contact">
                    Contact us
                  </Link>
                </span>

                <span className="landing__nav-link mobile-menu-btn">
                  <MobileMenuButton />
                </span>

              </span>
            </nav>

            <h1 className="landing__title">
              Connecting transfer students and universities
            </h1>
          </div>




          <div className="landing__content">
            <div className="landing__content-wrapper">
              <div className="landing__content-top">
                <h2 className="landing__content-header">
                  Transferring is hard. It doesn't have to be.
                </h2>
                <p className="landing__paragraph">
                  We're making the transfer process easier. Click 'get started' to learn more.
                </p>
              </div>

              <div className="landing__get-started-btn">
                <GetStartedButton landing='wide' />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
