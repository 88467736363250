import React from 'react';
import Fade from 'react-reveal/Fade';

// Components
import OneIcon from '../OneIcon';
import PhoneIcon from '../PhoneIcon';
import TwoIcon from '../TwoIcon';
import ExpertsIcon from '../ExpertsIcon';
import ThreeIcon from '../ThreeIcon';
import ThumbsUpIcon from '../ThumbsUpIcon';
import CurvedArrowIcon from '../CurvedArrowIcon';

// Styles
import './Solution.styles.scss';

const iconDimensions = '4em';
const numberDimensions = '2.5em';

const Solution = () => {
  return (
    <div className="solution">
      <Fade clear>
        <div className="solution__step">
          <div className="solution__step-number-1">
            <OneIcon
              width={numberDimensions}
              height={numberDimensions}
              color='rgb(255,140,0)'
            />
          </div>
          <div className="solution__step-icon">
            <PhoneIcon
              width={iconDimensions}
              height={iconDimensions}
              color='#001533'
            />
          </div>
          <p className="solution__step-paragraph">
            Students fill out digital forms describing
            their needs
          </p>
        </div>
      </Fade>

      <Fade clear>
        <div className="solution__step">
          <div className="solution__step-arrow">
          <CurvedArrowIcon
            width={iconDimensions}
            height={iconDimensions}
            color='#ff8c00'
            transform='rotate(45, 0, 0)'
          />
          </div>
          <div className="solution__step-number-2">
            <TwoIcon
              width={numberDimensions}
              height={numberDimensions}
              color='rgba(0, 21, 150, .75)'
            />
          </div>
          <div className="solution__step-icon">
            <ExpertsIcon
              width={iconDimensions}
              height={iconDimensions}
              color='#001533'
            />
          </div>
          <p className="solution__step-paragraph">
            Our proprietary technology matches transfer students with university programs
          </p>
        </div>
      </Fade>

      <Fade clear>
        <div className="solution__step step-3">
          <div className="solution__step-arrow">
            <CurvedArrowIcon
              width={iconDimensions}
              height={iconDimensions}
              color='#ff8c00'
              transform='rotate(310, 0, 0) scale(-1, 1)'
            />
          </div>
          <div className="solution__step-number-3">
            <ThreeIcon
              width={numberDimensions}
              height={numberDimensions}
              color='rgb(255, 140, 0)'
            />
          </div>
          <div className="solution__step-icon">
            <ThumbsUpIcon
              width={iconDimensions}
              height={iconDimensions}
              color='#001533'
            />
          </div>
          <p className="solution__step-paragraph">
            Students review their matches and connect with their favorite programs
          </p>
        </div>
      </Fade>

      <div className="solution--desktop">
        <Fade clear>
          <div className="solution__step--desktop">
            <div className="solution__step-number-1--desktop">
              <OneIcon
                width={numberDimensions}
                height={numberDimensions}
                color='rgb(255,140,0)'
              />
            </div>
            <div className="solution__step-icon--desktop">
              <PhoneIcon
                width={iconDimensions}
                height={iconDimensions}
                color='#001533'
              />
            </div>
            <p className="solution__step-paragraph--desktop">
              Students fill out digital forms describing
              their needs
            </p>
          </div>
        </Fade>

        <Fade clear>
          <div className="solution__step--desktop">
            <div className="solution__step-number-2--desktop">
              <TwoIcon
                width={numberDimensions}
                height={numberDimensions}
                color='rgba(0, 21, 150, .75)'
              />
            </div>
            <div className="solution__step-icon--desktop">
              <ExpertsIcon
                width={iconDimensions}
                height={iconDimensions}
                color='#001533'
              />
            </div>
            <p className="solution__step-paragraph--desktop">
              Our proprietary technology matches transfer students with university programs
            </p>
          </div>
        </Fade>

        <Fade clear>
          <div className="solution__step--desktop">
            <div className="solution__step-number-3--desktop">
              <ThreeIcon
                width={numberDimensions}
                height={numberDimensions}
                color='rgb(255,140,0)'
              />
            </div>
            <div className="solution__step-icon--desktop">
              <ThumbsUpIcon
                width={iconDimensions}
                height={iconDimensions}
                color='#001533'
              />
            </div>
            <p className="solution__step-paragraph--desktop">
              Students review their matches and connect with their favorite programs
            </p>
          </div>
        </Fade>
      </div>

    </div>
  );
};

export default Solution;
