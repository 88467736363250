import React from 'react';
import Fade from 'react-reveal/Fade';

import DiplomaIcon from '../DiplomaIcon';
import MoreJobsIcon from '../MoreJobsIcon';
import HigherWagesIcon from '../HigherWagesIcon';

import './StudentsWhy.styles.scss';

const svgProps = {
  mobile: {
    width: '3.5em',
    height: '3.5em',
    color: '#001533'
  },
  tabletUp: {
    width: '4.5em',
    height: '4.5em',
    color: '#001533'
  }
}

const StudentsWhy = () => {
  return (
    <div className="students-why">
      <div className="students-why__body">

        <Fade clear>
          <h2 className="students-why__title">
            Degrees matter now more than ever
          </h2>
        </Fade>

        <div className="students-why__stats">

          <Fade clear>
            <div className="students-why__stats-section">
              <div className="students-why__icon-container--mobile">
                <DiplomaIcon
                  width={svgProps.mobile.width}
                  height={svgProps.mobile.height}
                  color={svgProps.mobile.color}
                />
              </div>
              <div className="students-why__icon-container--desktop">
                <DiplomaIcon
                  width={svgProps.tabletUp.width}
                  height={svgProps.tabletUp.height}
                  color={svgProps.tabletUp.color}
                />
              </div>

              <div className="students-why__info">
                <div className="students-why__info-wrapper">
                  {'Job prospects and earning potential increase substantially with a four-year degree. '}
                </div>
              </div>
            </div>
          </Fade>

          <Fade clear>
            <div className="students-why__stats-section">
              <div className="students-why__icon-container--mobile">
                <MoreJobsIcon
                  width={svgProps.mobile.width}
                  height={svgProps.mobile.height}
                  color={svgProps.mobile.color}
                />
              </div>
              <div className="students-why__icon-container--desktop">
                <MoreJobsIcon
                  width={svgProps.tabletUp.width}
                  height={svgProps.tabletUp.height}
                  color={svgProps.tabletUp.color}
                />
              </div>
              <div className="students-why__info">
                <div className="students-why__info-wrapper">
                  <span className="orange_percentage">
                    99%
                  </span>
                  {' of new jobs have gone to people with at least an associate degree since 2010.'}
                </div>
              </div>
            </div>
          </Fade>

          <Fade clear>
            <div className="students-why__stats-section">
              <div className="students-why__icon-container--mobile">
                <HigherWagesIcon
                  width={svgProps.mobile.width}
                  height={svgProps.mobile.height}
                  color={svgProps.mobile.color}
                />
              </div>
              <div className="students-why__icon-container--desktop">
                <HigherWagesIcon
                  width={svgProps.tabletUp.width}
                  height={svgProps.tabletUp.height}
                  color={svgProps.tabletUp.color}
                />
              </div>
              <div className="students-why__info">
                <div className="students-why__info-wrapper">
                  {'In 2016, the '}
                  <span className="orange_percentage">
                    33%
                  </span>
                   {' of American adults holding at least a Bachelor\'s degree earned '}
                   <span className="orange_percentage">
                     57%
                   </span>
                   {' of total wages.'}
                </div>
              </div>
            </div>
          </Fade>

        </div>
      </div>
    </div>
  );
};

export default StudentsWhy;
