import React from 'react';
import { Link } from 'gatsby';

import './ContactUs.styles.scss';

const ContactUs = () => {
  return (
    <div className="contact-us-wrapper">
      <div className="contact-us__text">
        <h2 className="contact-us__title">
          Stay in touch
        </h2>

        <p className="contact-us__paragraph">
          Want to learn more?
        </p>
      </div>

      <Link to="/contact" className="contact-us__button">
        Contact us
      </Link>
    </div>
  );
};

export default ContactUs;
