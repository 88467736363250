import React, { Component } from 'react';

export default class ExpertsIcon extends Component {
  render() {
    const { width, height, color } = this.props;

    return (
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      	 viewBox="0 0 512 512" xmlSpace="preserve"
         width={width}
         height={height}
         fill={color}
        >
      <g>
      	<g>
      		<path d="M262.809,494.93c-1.859-1.86-4.439-2.93-7.069-2.93c-2.631,0-5.211,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
      			s1.069,5.21,2.93,7.07c1.859,1.86,4.439,2.93,7.07,2.93c2.63,0,5.21-1.07,7.069-2.93c1.86-1.86,2.931-4.44,2.931-7.07
      			S264.67,496.79,262.809,494.93z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M458.56,327.071l-33.358-7.969V305.87c18.516-11.703,30.848-32.342,30.848-55.818v-37.267c0-36.393-29.608-66-66.002-66
      			c-19.632,0-37.279,8.626-49.38,22.275c-10.598-36.703-44.495-63.623-84.564-63.623h-0.001c-40.134,0-74.077,27.005-84.616,63.798
      			c-12.104-13.75-29.816-22.45-49.532-22.45c-36.394,0-66.002,29.608-66.002,66v37.267c0,23.477,12.332,44.116,30.848,55.818v13.232
      			l-33.358,7.969C21.976,334.587,0,362.43,0,394.779V462c0,5.523,4.478,10,10,10h79.963v30c0,5.523,4.478,10,10,10h109.704
      			c5.522,0,10-4.477,10-10s-4.478-10-10-10h-44.643v-44.012c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10V492h-35.062v-45.118
      			c0-33.914,23.034-63.119,56.015-71.022l49.183-11.786l31.646,71.996c1.596,3.631,5.187,5.976,9.153,5.976h0.001
      			c3.966,0,7.558-2.344,9.154-5.975l31.614-71.891l49.04,11.715c33.011,7.885,56.065,37.096,56.065,71.034v11.159
      			c-0.511,1.202-0.795,2.523-0.795,3.911s0.284,2.71,0.795,3.911V492h-34.857v-44.012c0-5.523-4.478-10-10-10
      			c-5.522,0-10,4.477-10,10V492h-48.975c-5.522,0-10,4.477-10,10s4.478,10,10,10h113.832c5.522,0,10-4.477,10-10v-30H502
      			c5.522,0,10-4.477,10-10v-67.221C512,362.43,490.024,334.587,458.56,327.071z M390.047,166.785c25.365,0,46.002,20.636,46.002,46
      			v2.476v1.005h-28.603c-21.606,0-42.837-5.673-61.548-16.426C351.504,180.758,369.174,166.785,390.047,166.785z M344.045,248.42
      			c0.035-1.021,0.06-26.888,0.06-26.888c19.659,9.668,41.332,14.735,63.342,14.735h28.603v13.786
      			c-0.001,25.364-20.638,45.999-46.003,45.999s-46.002-20.636-46.002-46V248.42z M188.1,193.438c0-37.496,30.506-68,68.003-68
      			c37.496,0,68.002,30.505,68.002,68v1.304l-37.115-21.805c-4.211-2.475-9.593-1.544-12.73,2.199
      			c-14.42,17.208-35.568,27.078-58.021,27.078H188.1V193.438z M75.951,215.262v-2.477c0-25.365,20.637-46,46.002-46
      			c20.873,0,38.543,13.973,44.148,33.056c-18.711,10.752-39.941,16.426-61.548,16.426H75.951V215.262z M75.951,250.052v-13.786
      			h28.603c22.032,0,43.727-5.076,63.401-14.763v28.549c0,25.365-20.637,46-46.002,46S75.951,275.416,75.951,250.052z
      			 M89.963,446.882V452H60v-44.429c0-5.523-4.478-10-10-10s-10,4.477-10,10V452H20v-57.221c0-23.055,15.662-42.898,38.087-48.255
      			l31.698-7.573l20.688,49.555C97.534,404.58,89.963,424.954,89.963,446.882z M121.955,364.094l-15.187-36.379
      			c0.017-0.239,0.031-0.478,0.031-0.72v-12.718c4.869,1.148,9.939,1.775,15.154,1.775c5.192,0,10.238-0.622,15.088-1.76v12.07
      			c-0.011,0.188-0.016,0.376-0.016,0.564c0,0.315,0.03,0.628,0.06,0.941L121.955,364.094z M161.317,356.411
      			c-5.905,1.415-11.548,3.384-16.906,5.812l9.74-23.322l31.703,7.597c2.459,0.589,4.855,1.368,7.174,2.314L161.317,356.411z
      			 M210.953,335.568c-6.278-3.894-13.166-6.777-20.438-8.519l-33.474-8.021v-13.115c8.325-5.247,15.396-12.299,20.667-20.607
      			c7.539,14.729,19.11,27.063,33.245,35.547V335.568z M280.933,350.413l-24.971,56.786l-25.009-56.897v-20.624
      			c7.972,2.382,16.413,3.667,25.149,3.667c8.62,0,16.953-1.252,24.831-3.573V350.413z M256.103,313.346
      			c-37.498-0.001-68.003-30.506-68.003-68.001v-23.13h28.138c25.524,0,49.715-10.088,67.587-27.941l40.221,23.629v29.789
      			C322.802,284.103,292.813,313.346,256.103,313.346z M300.933,321.041h0.001c14.222-8.455,25.874-20.798,33.471-35.559
      			c5.26,8.231,12.289,15.22,20.554,20.431v13.115l-33.474,8.021c-7.316,1.753-14.243,4.662-20.552,8.592V321.041z M350.414,356.444
      			l-31.625-7.556c2.376-0.98,4.833-1.785,7.357-2.39l31.626-7.578l9.78,23.426C362.125,359.874,356.404,357.875,350.414,356.444z
      			 M374.894,328.016c0.04-0.363,0.069-0.728,0.069-1.094c0-0.093-0.001-0.187-0.004-0.28v-12.35c4.85,1.139,9.896,1.76,15.088,1.76
      			c5.215,0,10.285-0.627,15.154-1.776v12.718c0,0.182,0.013,0.361,0.023,0.541l-15.268,36.559L374.894,328.016z M492,452h-20
      			v-44.429c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10V452h-30.167v-5.07c0-21.899-7.546-42.245-20.448-58.305l20.753-49.692
      			l31.775,7.591c22.425,5.357,38.087,25.2,38.087,48.255V452z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M229.529,234.18c-1.859-1.86-4.439-2.93-7.069-2.93s-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07s1.069,5.21,2.93,7.07
      			c1.861,1.86,4.44,2.93,7.07,2.93c2.64,0,5.21-1.07,7.069-2.93c1.87-1.86,2.931-4.44,2.931-7.07S231.399,236.04,229.529,234.18z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M296.399,234.18c-1.859-1.86-4.439-2.93-7.069-2.93s-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07s1.069,5.21,2.93,7.07
      			c1.861,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.069-2.93c1.87-1.86,2.931-4.44,2.931-7.07S298.269,236.04,296.399,234.18z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M275.247,267.842c-3.906-3.905-10.236-3.905-14.143,0c-2.957,2.957-7.768,2.957-10.725,0
      			c-3.906-3.905-10.236-3.905-14.143,0c-3.905,3.905-3.905,10.237,0,14.143c5.378,5.377,12.441,8.066,19.505,8.066
      			c7.064,0,14.127-2.688,19.505-8.066C279.152,278.079,279.152,271.747,275.247,267.842z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M413.479,54h-6.859c-5.522,0-10,4.477-10,10s4.478,10,10,10h6.859c5.522,0,10-4.477,10-10S419.001,54,413.479,54z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M462,54h-6.859c-5.522,0-10,4.477-10,10s4.477,10,10,10H462c5.522,0,10-4.477,10-10S467.522,54,462,54z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M434.311,74.831c-5.522,0-10,4.477-10,10v6.859c0,5.523,4.477,10,10,10c5.522,0,10-4.477,10-10v-6.859
      			C444.311,79.308,439.833,74.831,434.311,74.831z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M434.311,26.31c-5.522,0-10,4.477-10,10v6.859c0,5.523,4.477,10,10,10c5.522,0,10-4.477,10-10V36.31
      			C444.311,30.787,439.833,26.31,434.311,26.31z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M56.302,54h-6.858c-5.522,0-10,4.477-10,10s4.478,10,10,10h6.858c5.522,0,10-4.477,10-10S61.824,54,56.302,54z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M104.823,54h-6.858c-5.522,0-10,4.477-10,10s4.477,10,10,10h6.858c5.522,0,10-4.477,10-10S110.345,54,104.823,54z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M77.134,74.831c-5.522,0-10,4.477-10,10v6.859c0,5.523,4.477,10,10,10c5.522,0,10-4.477,10-10v-6.859
      			C87.134,79.308,82.656,74.831,77.134,74.831z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M77.134,26.31c-5.522,0-10,4.477-10,10v6.859c0,5.523,4.477,10,10,10c5.522,0,10-4.477,10-10V36.31
      			C87.134,30.787,82.656,26.31,77.134,26.31z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M256,38.087c-5.522,0-10,4.477-10,10v26.31c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10v-26.31
      			C266,42.564,261.522,38.087,256,38.087z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M263.069,2.93C261.21,1.07,258.63,0,256,0s-5.21,1.07-7.07,2.93C247.069,4.79,246,7.37,246,10s1.069,5.21,2.93,7.07
      			c1.861,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.069-2.93C264.93,15.21,266,12.63,266,10S264.93,4.79,263.069,2.93z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M220.955,40.119l-14.634-14.634c-3.906-3.905-10.236-3.905-14.143,0c-3.905,3.905-3.905,10.237,0,14.143l14.635,14.634
      			c1.953,1.953,4.512,2.929,7.071,2.929c2.559,0,5.118-0.976,7.071-2.929C224.86,50.357,224.86,44.025,220.955,40.119z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M319.82,24.935c-3.906-3.905-10.236-3.905-14.143,0l-15.123,15.123c-3.905,3.905-3.905,10.237,0,14.143
      			c1.954,1.953,4.512,2.929,7.072,2.929s5.118-0.976,7.071-2.929l15.123-15.123C323.725,35.173,323.725,28.841,319.82,24.935z"/>
      	</g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      </svg>
    );
  }
}
