import React from 'react';
import GetStartedButton from '../GetStartedButton';

import './AddingValue.styles.scss';

const AddingValue = () => {
  return (
    <div className="adding-value">
      <div className="adding-value__wrapper">
        <div className="adding-value__img-container">
          <div className="adding-value__img">
            <span className="adding-value__img-overlay-text">
              <h2>
                Let's connect
              </h2>

              <p>
                Interested in participating in our pilot?
              </p>
            </span>

            <span className="adding-value__img-overlay-btn">
              <GetStartedButton landing="landing" />
            </span>
          </div>
        </div>
        <div className="adding-value__text">
          <h2 className="adding-value__text-title">
            We add value to the transfer process
          </h2>

          <p className="adding-value__text-body">
            With a broader and more curated marketplace of transfer options,
            we make high-quality connections between students and university programs
            outside traditional transfer pathways.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddingValue;
