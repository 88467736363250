import React from 'react';

import './WhatIs.styles.scss';

const WhatIs = () => {
  return (
    <div className="what-is">

      <div className="what-is__wrapper">
        <div className="what-is__explainer">
          <h2 className="what-is__explainer-title">
            Making the transfer process easy
          </h2>

          <p className="what-is__explainer-paragraph">
            We are a two-way platform designed to connect students with
            the four-year degree program that is perfect for them.
          </p>
        </div>

        <div className="what-is__img-container">
          <div className="what-is__img" />
        </div>
      </div>

    </div>
  );
};

export default WhatIs;
