import React from 'react';
import { Link } from 'gatsby';

import './CheckoutBlog.styles.scss';

const CheckoutBlog = () => {
  return (
    <div className="checkout-blog-wrapper">
      <div className="checkout-blog__text">
        <h2 className="checkout-blog__title">
          Keep up
        </h2>

        <p className="checkout-blog__paragraph">
          Check out our blog for the latest
        </p>
      </div>

      <Link to="/blog" className="checkout-blog__button">
        View posts
      </Link>
    </div>
  );
};

export default CheckoutBlog;
