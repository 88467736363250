import React from 'react';

import './ThisIsTransferableVideo.styles.scss';

const ThisIsTransferableVideo = () => {
    return (
        <section className="transferable-video-wrapper">
            <div className="transferable-video">
                <iframe 
                    className="transferable-video__iframe"
                    title="This Is Transferable Introduction Video"
                    src="https://www.youtube.com/embed/BzVqAkj_AkA"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                </iframe>
            </div>
        </section>
    );
};

export default ThisIsTransferableVideo;