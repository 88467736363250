import React, { Component } from 'react';

export default class CurvedArrowSvg extends Component {
  render() {
    const { width, height, color, transform } = this.props;
    return (
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      	 width={width}
         height={height}
         fill={color}
         transform={transform}
         viewBox="0 0 365.07 365.07"
      	 xmlSpace="preserve">
      <g>
      	<g>
      		<path d="M44.982,3.271c-3.672,2.448-8.568,3.06-11.628,6.12c-2.448,1.836-2.448,5.508,0,7.344c2.448,1.224,5.508,0,7.956-0.612
      			c1.836-0.612,2.448-1.836,2.448-3.672c1.836-1.224,3.672-2.448,4.896-4.284C51.102,5.719,48.042,0.823,44.982,3.271z"/>
      		<path d="M20.502,39.379c-3.672,3.06-7.344,6.732-10.404,10.404c-3.06,3.672-6.732,8.568-6.732,12.852
      			c0,2.448,3.06,3.672,4.284,1.836c3.06-3.06,3.672-7.956,6.12-11.628c2.448-4.284,5.508-7.344,9.18-11.016
      			C24.174,39.991,21.726,38.155,20.502,39.379z"/>
      		<path d="M5.814,94.459C4.59,89.563,3.978,84.055,3.978,78.547c0-1.836-3.06-1.836-3.06,0c0,7.344-3.672,22.032,3.672,26.928
      			c1.224,1.224,3.06-0.612,3.06-1.836C7.65,100.579,6.426,97.519,5.814,94.459z"/>
      		<path d="M40.086,117.715c-9.792,1.224-18.36,3.06-27.54-1.836c-1.224-0.612-1.836,0.612-0.612,1.224
      			c9.18,6.732,19.584,9.792,29.988,4.284C44.37,120.163,41.922,117.103,40.086,117.715z"/>
      		<path d="M84.15,85.891c-4.896,1.836-8.568,5.508-12.852,8.568c-4.896,3.672-9.18,7.344-13.464,11.628
      			c-1.224,1.224,0.612,3.672,2.448,2.448c3.672-3.672,7.956-6.12,12.24-9.18c4.284-3.06,9.792-5.508,13.464-9.18
      			C87.822,88.339,86.598,84.667,84.15,85.891z"/>
      		<path d="M125.154,41.827c-11.016,4.284-21.42,14.688-29.376,23.256c-1.224,1.836,0.612,3.672,2.448,2.448
      			c4.896-4.284,10.404-8.568,15.912-12.24c4.284-3.06,9.18-5.508,12.852-9.18C128.826,43.663,126.99,41.215,125.154,41.827z"/>
      		<path d="M179.622,19.183c-11.016-0.612-22.032,3.672-31.824,7.344c-1.836,0.612-1.224,3.06,0.612,3.06
      			c10.404-3.06,20.808-4.284,31.212-4.896C182.682,24.079,182.682,19.183,179.622,19.183z"/>
      		<path d="M220.626,39.379c-3.06-3.672-7.344-7.344-11.016-10.404c-3.06-2.448-6.732,1.224-4.284,4.284
      			c3.061,3.06,6.12,6.732,9.18,10.404c3.061,3.672,6.12,9.18,10.404,12.24c1.836,1.224,4.284-0.612,3.672-3.06
      			C228.582,47.947,224.298,43.051,220.626,39.379z"/>
      		<path d="M232.254,72.427c0-2.448-4.283-2.448-4.283,0c-0.612,6.12-1.836,11.628-3.061,17.748
      			c-1.224,5.508-4.284,11.016-4.284,17.136c0,3.06,4.284,4.284,5.509,1.224C231.643,98.131,232.866,83.443,232.254,72.427z"/>
      		<path d="M205.938,134.239c-3.061,4.284-6.12,8.568-9.181,12.852c-2.447,4.284-6.731,8.568-7.344,13.464
      			c0,2.448,2.448,3.06,4.284,2.448c4.284-1.836,6.12-7.344,7.956-11.016c3.06-4.896,5.508-10.404,8.568-15.3
      			C211.446,133.627,207.774,131.791,205.938,134.239z"/>
      		<path d="M175.338,178.915c-5.508,4.896-9.792,9.792-13.464,15.3c-3.672,5.508-8.568,13.464-7.344,20.196
      			c0.612,3.06,3.672,3.672,5.508,1.224c3.06-4.284,3.672-10.404,5.508-15.3c2.448-7.344,6.732-14.076,11.628-20.196
      			C178.398,179.527,176.562,177.691,175.338,178.915z"/>
      		<path d="M140.454,238.891c-5.508,4.896-9.792,10.404-13.464,17.136c-3.672,6.732-7.956,15.301-6.732,22.645
      			c0.612,2.448,4.284,2.448,4.896,0.612c3.672-5.509,3.672-12.853,6.12-18.973c2.448-6.731,6.732-13.464,11.628-19.584
      			C144.126,239.503,142.29,237.055,140.454,238.891z"/>
      		<path d="M139.842,332.526c-4.284-2.447-7.956-4.283-11.016-7.955c-3.06-4.896-4.284-12.24-4.284-17.748c0-1.837-3.06-1.837-3.06,0
      			c-1.224,11.628,1.224,31.823,17.136,31.212C141.066,337.423,142.29,333.751,139.842,332.526z"/>
      		<path d="M204.714,353.947c-6.731-0.612-13.464,0.611-20.195,0.611c-8.569-0.611-16.525-3.06-23.257-7.956
      			c-1.224-0.611-1.836,1.225-1.224,1.836c12.852,9.792,30.601,18.36,46.513,11.628C209.61,358.23,207.774,353.947,204.714,353.947z"
      			/>
      		<path d="M256.734,312.331c-4.284,2.448-7.956,6.12-12.852,9.18c-4.284,2.448-9.181,3.672-14.076,4.284
      			c-3.061,0.612-3.061,4.896,0,4.896c11.016,0.612,25.092-3.06,31.212-13.464C263.467,314.167,259.794,311.106,256.734,312.331z"/>
      		<path d="M281.826,275.61c-7.956,7.956-16.523,16.524-22.032,26.316c-1.836,3.061,2.448,4.896,4.284,2.448
      			c6.732-8.568,17.748-14.688,22.645-25.092C287.334,276.223,284.274,273.163,281.826,275.61z"/>
      		<path d="M327.727,276.223c-1.836-3.672-6.732-6.12-10.404-7.956c-4.284-1.836-9.18-2.447-14.076-2.447c-3.06,0-3.06,4.283,0,4.283
      			c4.284,0,7.956,1.225,11.628,3.061c3.061,1.836,5.509,4.896,8.568,6.731C325.891,281.73,328.95,278.671,327.727,276.223z"/>
      		<path d="M364.446,300.091c-8.568-14.076-14.076-29.988-21.42-44.676c-3.672-6.732-13.464-0.612-10.404,6.12
      			c5.508,12.239,10.404,25.092,17.748,36.72c-5.508,0.612-11.016,1.224-17.136,1.836c-2.448,0.612-6.732,0.612-8.568,3.061
      			l-0.611,0.611c0,0-0.612,0-0.612,0.612c-1.224,1.224,0,3.672,1.224,3.672l0,0c0.612,0.612,1.225,0.612,2.448,0.612
      			c3.061,0.611,6.12,0,9.792,0c7.956-0.612,15.3-1.225,23.256-0.612C363.834,308.659,366.282,303.763,364.446,300.091z"/>
      	</g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      </svg>

    );
  }
}
