import React from 'react';
import CheckoutBlog from '../CheckoutBlog';
import ContactUs from '../ContactUs';

import './StayConnected.styles.scss';

const StayConnected = () => {
  return (
    <div className="stay-connected">
      <div className="stay-connected__wrapper">
        <div className="stay-connected__checkout-blog-container">
          <CheckoutBlog />
        </div>
        <div className="stay-connected__contact-us-container">
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default StayConnected;
