import React from 'react';
import Fade from 'react-reveal/Fade';

import TransferStudentIcon from '../TransferStudentIcon';
import HandMoneyIcon from '../HandMoneyIcon';
import StudentSearchIcon from '../StudentSearchIcon';
import LightBulbIcon from '../LightBulbIcon';
import ThumbsUpIcon from '../ThumbsUpIcon';

import './UniWhy.styles.scss';

const svgDimensions = {
  width: '2.5em',
  height: '2.5em'
};

const percentageStyles = {
  color: '#ff8c00',
  fontSize: '1.25em',
};

const UniWhy = () => {
  return (
    <div className="uni-why">
      <div className="uni-why__wrapper">

        <div className="uni-why__header">
          <TransferStudentIcon
            width="4em"
            height="4em"
            color="rgb(255, 255, 255)"
          />

          <Fade clear>
            <h2 className="uni-why__title">
              Universities need transfer students
            </h2>
          </Fade>
        </div>

        <div className="uni-why__sections-wrapper">

          <div className="uniwhy__sections-row">
            <Fade clear>
              <div className="uni-why__section section-1">
                <div className="uni-why__reason reason-1">
                  <HandMoneyIcon
                    width={svgDimensions.width}
                    height={svgDimensions.width}
                    color="rgb(255, 255, 255)"
                  />
                  <h4>
                    Universities are tuition dependent
                  </h4>
                </div>

                <div className="uni-why__stats stats-1">
                  <div className="uni-why__stats-wrapper">
                    <p>
                      Percentage of revenue from tuition and fees for the median university:
                    </p>

                    <div className="uni-why__percentages">
                      <div className="uni-why__type">
                        <span className="uni-why__percentage-span">
                          50%
                        </span>
                        <span className="uni-why__description">
                          Public Universities
                        </span>
                      </div>
                      <div className="uni-why__type">
                        <span className="uni-why__percentage-span">
                          74%
                        </span>
                        <span className="uni-why__description">
                          Private Universities
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
          <div className="uniwhy__sections-row"></div>

          <Fade clear>
            <div className="uni-why__section section-2">
              <div className="uni-why__reason">
                <StudentSearchIcon
                  width={svgDimensions.width}
                  height={svgDimensions.width}
                  color="rgb(255, 255, 255)"
                />
                <h4>
                  It's becoming harder to find traditional students
                </h4>
              </div>

              <div className="uni-why__stats">
                <div className="uni-why__stats-wrapper">
                  {'High school graduates grew '}
                    <span className="uni-why__percentage-span"
                      style={percentageStyles}
                    >
                      30%
                    </span>
                    {' from 1995 to 2013 but are projected to fall more than '}
                    <span className="uni-why__percentage-span"
                      style={percentageStyles}
                    >
                      10%
                    </span>
                    {' from 2018 to 2030.'}
                </div>
              </div>
            </div>
          </Fade>

          <Fade clear>
            <div className="uni-why__section section-3">
              <div className="uni-why__reason reason-3">
                <LightBulbIcon
                  width={svgDimensions.width}
                  height={svgDimensions.width}
                  color="rgb(255, 255, 255)"
                />
                <h4>
                  The answer is clear
                </h4>
              </div>

              <div className="uni-why__stats stats-3">
                <div className="uni-why__stats-wrapper">
                  Universities can't rely solely on increasing enrollment from the same places as always to grow revenue.
                  Their financial sustainability depends on broadening their enrollment pipelines.
                </div>
              </div>
            </div>
          </Fade>

          <Fade clear>
            <div className="uni-why__section section-4">
              <div className="uni-why__reason reason-4">
                <ThumbsUpIcon
                  width={svgDimensions.width}
                  height={svgDimensions.width}
                  color="rgb(255, 255, 255)"
                />
                <h4>
                  University business officers agree
                </h4>
              </div>

              <div className="uni-why__stats stats-4">
                <div className="uni-why__stats-wrapper">
                  {'After only '}
                  <span className="orange_percentage"
                    style={percentageStyles}
                  >
                    34%
                  </span>
                  {' of universities met their enrollment targets for fall 2017 by May 1, '}
                  <span className="orange_percentage"
                    style={percentageStyles}
                  >
                    63%
                  </span>
                  {' of publics and '}
                  <span className="orange_percentage"
                    style={percentageStyles}
                  >
                    85%
                  </span>
                  {' of privates say transfer students will be a target of increased recruiting in the coming year.'}
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>

    </div>
  );
};

export default UniWhy;
