import React from 'react';
import dropdownNav from '../hocs/dropdownNav';

// Components
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Landing from '../components/Landing';
import WhatIs from '../components/WhatIs';
import Solution from '../components/Solution';
import ThisIsTransferableVideo from '../components/ThisIsTransferableVideo';
import Problem from '../components/Problem';
import StudentsWhy from '../components/StudentsWhy';
import UniWhy from '../components/UniWhy';
import AddingValue from '../components/AddingValue';
import WhereTransferableCanHelp from '../components/WhereTransferableCanHelp';
import StayConnected from '../components/StayConnected';

// Styles
import "./index.styles.scss"

const IndexPage = props => (
  <Layout currentPath="/">
    <SEO title="Home" />
    <div className="landing-page">
      <Landing {...props} />
      <WhatIs />
      <Solution />
      <ThisIsTransferableVideo />
      <Problem />
      <StudentsWhy />
      <UniWhy />
      <AddingValue />
      <WhereTransferableCanHelp />
      <StayConnected />
    </div>
  </Layout>
);

export default dropdownNav(IndexPage);
