import React, { Component } from 'react';
import Radium from 'radium';

import Fade from 'react-reveal/Fade';
import { IconContext } from 'react-icons';
import { FaCheck } from 'react-icons/fa';

import './How.styles.scss';

const styles = {
  tab: {
    minHeight: '100%',
    margin: '0',
    paddingBottom: '.75rem',
    ':hover': {
      cursor: 'pointer'
    },
  },
  active: {
    borderBottom: '2px solid #ff8c00',
    color: '#ff8c00',
  },
  checkmark: {
    marginRight: '1rem',
    fontSize: '1rem',
    minHeight: '2rem',
    minWidth: '1.5rem',
    color: 'rgb(33, 206, 153)'
  },
  middle_checkmark: {
    marginRight: '1rem',
    fontSize: '65%',
    minHeight: '2rem',
    minWidth: '1.5rem',
    color: 'rgb(33, 206, 153)'
  },
  point: {
    padding: '.5rem 0 .5rem .5rem',

  },
};

class How extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students_tab_active: true,
      universities_tab_active: false,
    };
  }

  toggleTabs(e) {
    if (e.target.innerText === 'Universities') {
      if (!this.state.universities_tab_active) {
        this.setState({
          students_tab_active: false,
          universities_tab_active: true
        });
      }
    }

    if(e.target.innerText === 'Students') {
      if (!this.state.students_tab_active) {
        this.setState({
          students_tab_active: true,
          universities_tab_active: false
        });
      }
    }
  }

  handleTabClick(e) {
    this.toggleTabs(e);
  }

  render() {
    const { students_tab_active, universities_tab_active } = this.state;
    const studentTabStyles = students_tab_active ? [styles.tab, styles.active] : styles.tab;
    const universitiesTabStyles = universities_tab_active ? [styles.tab, styles.active] : styles.tab;
    return (
      <div className="how">

        <div className="how__display">
          <div className="how__choices">
            <h3
              className="tab"
              style={studentTabStyles}
              onClick={(e) => this.handleTabClick(e)}
              key='students_tab'
            >
              Students
            </h3>
            <h3
              className="tab"
              style={universitiesTabStyles}
              onClick={(e) => this.handleTabClick(e)}
              key='universities_tab'
            >
              Universities
            </h3>
          </div>
          <div className="how__actions" style={ styles.how__actions }>
            {
              students_tab_active
                &&
                <Fade clear>
                  <ul className="how__actions-list student_actions">
                    <li className="how__actions-list-item">
                      <IconContext.Provider value={{ style: styles.checkmark }}>
                        <FaCheck />
                      </IconContext.Provider>
                      <div className="point" style={ styles.point }>
                        Empower them with a better understanding of their options.
                      </div>
                    </li>
                    <li className="how__actions-list-item">
                      <IconContext.Provider value={{ style: styles.middle_checkmark }}>
                        <FaCheck />
                      </IconContext.Provider>
                      <div className="point" style={ styles.point }>
                        Help them make decisions, without being flooded with unwanted ads.
                      </div>
                    </li>
                    <li className="how__actions-list-item">
                      <IconContext.Provider value={{ style: styles.checkmark }}>
                        <FaCheck />
                      </IconContext.Provider>
                      <div className="point" style={ styles.point }>
                        Provide access to the programs that meet their needs and interests.
                      </div>
                    </li>
                  </ul>
                </Fade>
            }
            {
              universities_tab_active
                &&
                <Fade clear>
                  <ul className="how__actions-list universities_actions" style={ styles.actions_list }>
                    <li className="how__actions-list-item">
                      <IconContext.Provider value={{ style: styles.checkmark }}>
                        <FaCheck />
                      </IconContext.Provider>
                      <div className="point" style={ styles.point }>
                        Get information about your offerings in front of students who may not have seen it otherwise.
                      </div>
                    </li>
                    <li className="how__actions-list-item">
                      <IconContext.Provider value={{ style: styles.middle_checkmark }}>
                        <FaCheck />
                      </IconContext.Provider>
                      <div className="point" style={ styles.point }>
                        Broaden the admissions pipeline with potential recruits that are good matches for existing programs.
                      </div>
                    </li>
                    <li className="how__actions-list-item">
                      <IconContext.Provider value={{ style: styles.checkmark }}>
                        <FaCheck />
                      </IconContext.Provider>
                      <div className="point" style={ styles.point }>
                        Allow for easy management of and outreach to prospective students.
                      </div>
                    </li>
                  </ul>
                </Fade>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(How);
