import React, { Component } from 'react';

export default class TransferStudentIcon extends Component {
  render() {

    const { width, height, color } = this.props;

    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      	viewBox="0 0 512 512" xmlSpace="preserve"
        width={width}
        height={height}
        fill={color}
      >
      <g>
      	<g>
      		<path d="M512,367.165V213.244c0-18.566-15.105-33.67-33.67-33.67H267.437v-3.955c0-5.193-1.837-9.961-4.89-13.7
      			c2.265-2.772,3.845-6.092,4.521-9.69c8.581-5.059,14.051-14.325,14.051-24.497c0-4.804-1.237-9.499-3.555-13.682
      			c2.317-4.183,3.555-8.877,3.555-13.682c0-10.13-5.427-19.314-13.842-24.376c0.107-0.992,0.16-1.988,0.16-2.988
      			c0-14.409-10.734-26.359-24.626-28.272c-5.072-8.523-14.305-13.949-24.437-13.949c-1.301,0-2.615,0.1-3.931,0.3
      			c-5.659-4.582-12.728-7.141-20.012-7.141c-0.973,0-1.948,0.049-2.936,0.147c-6.084-4.531-13.407-6.988-21.006-6.988
      			s-14.922,2.457-21.007,6.988c-0.989-0.098-1.964-0.147-2.936-0.147c-7.303,0-14.259,2.457-19.908,6.981
      			c-9.011,0.899-17.031,6.08-21.63,13.81c-13.892,1.912-24.626,13.862-24.626,28.271c0,0.999,0.053,1.996,0.16,2.988
      			c-8.414,5.062-13.842,14.246-13.842,24.376c0,4.805,1.237,9.499,3.555,13.682c-2.318,4.183-3.555,8.878-3.555,13.682
      			c0,10.172,5.469,19.438,14.052,24.497c0.377,2.008,1.044,3.926,1.946,5.714c-5.532,3.937-9.157,10.384-9.157,17.675v13.682
      			c0,11.964,9.733,21.699,21.699,21.699h3.563l0.583,3.642c1.351,8.446,5.714,16.114,12.286,21.591l2.916,2.43v22.625
      			c0,2.502-1.608,4.679-4,5.414L50.628,285.15c-14.938,4.597-24.975,18.186-24.975,33.816v99.506c0,9.887,3.426,18.985,9.14,26.188
      			H16.568C7.432,444.66,0,452.092,0,461.228v17.102c0,9.136,7.432,16.568,16.568,16.568h478.864c9.136,0,16.568-7.432,16.568-16.568
      			v-17.102c0-9.136-7.432-16.568-16.568-16.568h-87.044l-5.844-43.825h75.786C496.895,400.835,512,385.731,512,367.165z
      			 M95.238,194.966c-3.123,0-5.665-2.541-5.665-5.665v-13.682c0-2.07,1.128-3.865,2.79-4.854l3.873,24.201H95.238z M96.421,148.493
      			c0.032-0.243,0.062-0.485,0.084-0.731c0.333-3.664-1.872-7.085-5.346-8.296c-5.04-1.756-8.426-6.471-8.426-11.733
      			c0-3.877,1.776-6.662,3.265-8.316c2.746-3.05,2.746-7.682-0.001-10.732c-1.49-1.654-3.265-4.439-3.265-8.316
      			c0-5.586,3.808-10.518,9.262-11.994c2.21-0.599,4.06-2.113,5.08-4.163c1.022-2.049,1.118-4.438,0.265-6.563
      			c-0.615-1.527-0.926-3.09-0.926-4.643c0-6.798,5.452-12.347,12.214-12.503c0.341,0.046,0.684,0.084,1.033,0.105
      			c3.59,0.206,6.868-1.977,8.048-5.365c1.727-4.958,6.317-8.314,11.475-8.423c0.155,0.011,0.311,0.018,0.468,0.02
      			c2.167,0.026,4.221-0.788,5.755-2.293c3.006-2.948,6.962-4.572,11.138-4.572c1.081,0,2.227,0.14,3.503,0.427
      			c2.591,0.583,5.308-0.156,7.247-1.976c3.635-3.411,8.32-5.29,13.194-5.29s9.559,1.879,13.193,5.291
      			c1.938,1.82,4.654,2.56,7.247,1.976c1.276-0.288,2.422-0.427,3.503-0.427c4.477,0,8.646,1.878,11.739,5.287
      			c2.142,2.361,5.479,3.228,8.499,2.21c1.31-0.441,2.523-0.656,3.706-0.656c5.262,0,9.978,3.386,11.734,8.425
      			c1.18,3.387,4.461,5.579,8.048,5.365c0.327-0.019,0.649-0.055,1.031-0.105c6.762,0.155,12.215,5.705,12.215,12.503
      			c0,1.553-0.311,3.116-0.925,4.642c-0.854,2.125-0.758,4.514,0.264,6.564c1.022,2.05,2.871,3.565,5.082,4.163
      			c5.452,1.476,9.261,6.408,9.261,11.994c0,3.877-1.776,6.662-3.265,8.316c-2.747,3.049-2.747,7.682,0,10.732
      			c1.49,1.654,3.265,4.439,3.265,8.316c0,5.262-3.386,9.978-8.426,11.733c-3.474,1.211-5.679,4.632-5.346,8.296
      			c0.022,0.246,0.052,0.49,0.086,0.733c-0.106,2.434-1.81,4.545-4.217,5.185c-0.257,0.068-7.924,0.241-7.924,0.241l-4.61-27.66
      			c-0.682-4.091-4.38-6.986-8.506-6.676c-0.372,0.028-0.736,0.073-1.102,0.123l-0.047,0.006c-4.108-0.073-7.908-2.16-10.199-5.616
      			c-1.486-2.239-3.993-3.586-6.681-3.586c-2.687,0-5.196,1.347-6.681,3.585c-2.333,3.518-6.228,5.618-10.422,5.618
      			c-4.193,0-8.088-2.1-10.422-5.618c-1.486-2.239-3.993-3.586-6.681-3.586c-2.687,0-5.196,1.347-6.681,3.585
      			c-2.333,3.518-6.228,5.618-10.422,5.618c-4.193,0-8.088-2.1-10.422-5.618c-1.486-2.239-3.993-3.586-6.681-3.586
      			c-2.687,0-5.196,1.347-6.681,3.585c-2.292,3.456-6.092,5.543-10.199,5.616l-0.047-0.006c-0.364-0.05-0.731-0.095-1.103-0.123
      			c-4.125-0.315-7.823,2.586-8.505,6.676l-4.609,27.663c0,0-7.666-0.172-7.923-0.24C98.229,153.04,96.524,150.927,96.421,148.493z
      			 M251.404,175.619v3.955h-4.2l1.41-8.809C250.276,171.754,251.404,173.549,251.404,175.619z M108.47,169.954h1.085
      			c7.292,0,13.455-5.222,14.656-12.415l3.714-22.289c4.292-0.812,8.314-2.599,11.779-5.2c4.867,3.655,10.836,5.699,17.102,5.699
      			c6.265,0,12.235-2.045,17.102-5.699c4.867,3.655,10.836,5.699,17.102,5.699c6.265,0,12.235-2.045,17.102-5.699
      			c3.464,2.602,7.487,4.387,11.779,5.2l3.714,22.29c0.875,5.25,4.401,9.438,9.074,11.324l-1.713,10.71h-9.172
      			c-18.566,0-33.67,15.105-33.67,33.67v35.58c-1.443,0.563-2.985,0.869-4.544,0.869h-26.185c-2.92,0-5.763-1.029-8.006-2.899
      			l-27.457-22.881c-3.594-2.995-5.979-7.187-6.718-11.806L108.47,169.954z M115.302,282.026
      			c9.163-2.819,15.318-11.154,15.318-20.739v-9.264l8.506,7.089c5.119,4.266,11.607,6.615,18.271,6.615h26.185
      			c1.525,0,3.045-0.13,4.544-0.374v51.584c-4.704,0.768-9.486,1.166-14.286,1.166c-26.743,0-51.963-12.236-68.568-32.99
      			L115.302,282.026z M41.687,318.965c0-8.547,5.489-15.978,13.657-18.492l33.647-10.352c19.455,27.569,51.127,44.014,84.849,44.014
      			c4.789,0,9.564-0.337,14.286-0.988v34.018c0,18.566,15.104,33.67,33.67,33.67h34.739v43.29c0,0.181,0.015,0.357,0.027,0.534
      			H212.71v-9.086c0-18.566-15.105-33.67-33.67-33.67H93.528c-0.295,0-0.534-0.239-0.534-0.534v-51.307
      			c0-4.427-3.589-8.017-8.017-8.017s-8.017,3.589-8.017,8.017v51.307c0,9.136,7.432,16.568,16.568,16.568h51.841v26.722H67.875
      			c-14.44,0-26.188-11.748-26.188-26.188V318.965z M196.676,435.574v9.086h-35.273v-26.722h17.637
      			C188.764,417.937,196.676,425.849,196.676,435.574z M495.432,460.693c0.295,0,0.534,0.239,0.534,0.534v17.102
      			c0,0.295-0.239,0.534-0.534,0.534H16.568c-0.295,0-0.534-0.239-0.534-0.534v-17.102c0-0.295,0.239-0.534,0.534-0.534H495.432z
      			 M272.541,444.66c0.012-0.177,0.027-0.354,0.027-0.534v-43.29h25.012l-5.844,43.825H272.541z M307.912,444.66l13.913-104.349
      			c0.6-4.496,4.471-7.885,9.007-7.885h38.462c4.535,0,8.407,3.389,9.006,7.885l13.914,104.349H307.912z M401.37,384.802
      			c-0.324,0-0.64,0.025-0.955,0.062l-6.223-46.672c-1.657-12.427-12.361-21.799-24.898-21.799h-38.462
      			c-12.537,0-23.242,9.372-24.899,21.799l-6.223,46.672c-0.313-0.037-0.631-0.062-0.955-0.062h-76.96
      			c-9.725,0-17.637-7.912-17.637-17.637V213.244c0-9.725,7.912-17.637,17.637-17.637H478.33c9.725,0,17.637,7.912,17.637,17.637
      			v153.921c0,9.725-7.912,17.637-17.637,17.637H401.37z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M350.063,247.983c-13.851,0-25.119,11.268-25.119,25.119c0,13.851,11.268,25.119,25.119,25.119
      			c13.851,0,25.119-11.268,25.119-25.119C375.182,259.252,363.913,247.983,350.063,247.983z M350.063,282.188
      			c-5.01,0-9.086-4.076-9.086-9.086c0-5.01,4.076-9.086,9.086-9.086c5.01,0,9.086,4.076,9.086,9.086
      			C359.148,278.112,355.073,282.188,350.063,282.188z"/>
      	</g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      </svg>

    );
  }
}
