import React from 'react';
import How from '../How';

import './WhereTransferableCanHelp.styles.scss';

const WhereTransferableCanHelp = () => {
  return (
    <div className="can-help">
      <div className="can-help__wrapper">
        <div className="can-help__text">
          <h2 className="can-help__text-title">
            Where Transferable can help
          </h2>

          <p className="can-help__text-body">
            Working with each individual student's interests and academic
            history, we can make more and better degree program recommendations.
            Plus, by collecting student and course information up front, we can save
            university admissions offices time and hassle.
          </p>

          <How />
        </div>

        <div className="can-help__img-container">
          <div className="can-help__img" />
        </div>
      </div>
    </div>
  );
};

export default WhereTransferableCanHelp;
