import React, { Component } from 'react';

export default class MoreJobsIcon extends Component {
  render() {

    const { width, height, color } = this.props;

    return (
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      	viewBox="0 0 480 480" xmlSpace="preserve"
        width={width}
        height={height}
        fill={color}
      >
      <g>
      	<g>
      		<path d="M429.688,90.304l-40-40c-2.678-2.681-6.869-3.115-10.04-1.04L335.2,78.4l-47.928-20L279.92,6.864
      			C279.355,2.924,275.98-0.001,272,0h-64c-3.98-0.001-7.355,2.924-7.92,6.864L192.72,58.4l-47.88,20l-44.456-29.12
      			c-3.171-2.075-7.362-1.641-10.04,1.04l-40,40c-2.684,2.685-3.112,6.886-1.024,10.056l29.6,44.96L73.96,156.8l14.688,6.352
      			l6.68-15.424c1.073-2.478,0.823-5.33-0.664-7.584l-28.416-43.12L97.04,66.232l42.576,27.888c2.229,1.443,5.025,1.701,7.48,0.688
      			l56-23.448c2.586-1.096,4.408-3.467,4.8-6.248L214.936,16h50.128l7.016,49.136c0.392,2.781,2.214,5.152,4.8,6.248l56,23.432
      			c2.452,1.013,5.246,0.755,7.472-0.688l42.592-27.872l31.008,31.008l-20.8,35.2c-1.238,2.087-1.462,4.624-0.608,6.896l8.872,23.432
      			l14.968-5.664l-7.512-19.832l22.04-37.264C432.772,96.89,432.268,92.887,429.688,90.304z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M325.656,306.344l-24-24c-1.5-1.5-3.534-2.344-5.656-2.344H184c-2.122,0-4.156,0.844-5.656,2.344l-24,24
      			c-1.5,1.5-2.344,3.534-2.344,5.656v160c0,4.418,3.582,8,8,8h160c4.418,0,8-3.582,8-8V312
      			C328,309.878,327.156,307.844,325.656,306.344z M267.312,296h25.376L264,324.688L251.312,312L267.312,296z M252.688,336
      			l-10.344,10.344c-1.505,1.497-2.349,3.533-2.344,5.656c-0.015-2.102-0.857-4.114-2.344-5.6L227.312,336L240,323.312L252.688,336z
      			 M244.688,296L240,300.688L235.312,296H244.688z M212.688,296l16,16L216,324.688L187.312,296H212.688z M209,464h-41V315.312l8-8
      			l34.344,34.344l13.2,13.2L209,464z M225.144,464l14.784-110.944c0.048-0.337,0.072-0.676,0.072-1.016
      			c0.002,0.513,0.055,1.025,0.16,1.528L262.24,464H225.144z M312,464h-33.44l-21.872-109.368l12.968-12.976L304,307.312l8,8V464z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M296,104h-72c-26.499,0.026-47.974,21.501-48,48v48c0.04,35.33,28.67,63.96,64,64c35.33-0.04,63.96-28.67,64-64v-88
      			C304,107.582,300.418,104,296,104z M288,200c0,26.51-21.49,48-48,48c-26.51,0-48-21.49-48-48v-32h64
      			c11.824-0.003,23.227-4.393,32-12.32V200z M256,152h-64c0-17.673,14.327-32,32-32h64C288,137.673,273.673,152,256,152z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M477.656,338.344l-24-24c-1.5-1.5-3.534-2.344-5.656-2.344h-64c-2.122,0-4.156,0.844-5.656,2.344l-24,24
      			c-1.5,1.5-2.344,3.534-2.344,5.656v128c0,4.418,3.582,8,8,8h112c4.418,0,8-3.582,8-8V344
      			C480,341.878,479.156,339.844,477.656,338.344z M464,464h-40V344h-16v120h-40V347.312l16-16l18.344,18.344L408,344l5.656-5.656
      			L403.312,328h26.008l-10.808,10.168L424,344l5.488,5.832l19.072-17.96l15.44,15.44V464z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M424,176h-15.736c-22.02,0.044-39.881,17.844-40,39.864l-0.128,40.056c-0.088,26.465,21.294,47.991,47.76,48.08
      			c0.053,0,0.107,0,0.16,0c26.457-0.031,47.9-21.463,47.944-47.92V216C463.974,193.92,446.08,176.026,424,176z M384.264,215.92
      			C384.307,202.799,394.88,192.144,408,192c0.001,13.152-10.584,23.855-23.736,24V215.92z M448,256.056
      			c-0.031,17.62-14.3,31.9-31.92,31.944c-17.629,0-31.92-14.291-31.92-31.92c0-0.027,0-0.053,0-0.08l0.08-24
      			c12.506-0.075,24.257-5.995,31.76-16c7.554,10.072,19.41,16,32,16V256.056z M424,192c13.255,0,24,10.745,24,24
      			C434.745,216,424,205.255,424,192z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M125.656,338.344l-24-24c-1.5-1.5-3.534-2.344-5.656-2.344H32c-2.122,0-4.156,0.844-5.656,2.344l-24,24
      			C0.844,339.844,0,341.878,0,344v128c0,4.418,3.582,8,8,8h112c4.418,0,8-3.582,8-8V344
      			C128,341.878,127.156,339.844,125.656,338.344z M112,464H72V344H56v120H16V347.312l16-16l18.344,18.344L56,344l5.656-5.656
      			L51.312,328H77.32l-10.808,10.168L72,344l5.488,5.832l19.072-17.96l15.44,15.44V464z"/>
      	</g>
      </g>
      <g>
      	<g>
      		<path d="M72,176H56.264c-22.02,0.044-39.881,17.844-40,39.864l-0.128,40.056c-0.088,26.465,21.294,47.991,47.76,48.08
      			c0.053,0,0.107,0,0.16,0c26.457-0.031,47.9-21.463,47.944-47.92V216C111.974,193.92,94.08,176.026,72,176z M72,192
      			c10.134,0.042,19.15,6.445,22.528,16H80c-10.134-0.042-19.15-6.445-22.528-16H72z M40,198.464v1.464
      			c0.01,2.129-0.841,4.172-2.36,5.664c-1.04,1.012-2.347,1.706-3.768,2C35.181,204.116,37.279,200.992,40,198.464z M96,256.08
      			c-0.044,17.611-14.309,31.876-31.92,31.92c-17.629,0-31.92-14.291-31.92-31.92c0-0.027,0-0.053,0-0.08l0.104-32.064
      			c6.263-0.035,12.26-2.539,16.688-6.968c1.265-1.281,2.383-2.697,3.336-4.224C59.711,219.955,69.651,223.992,80,224h16V256.08z"/>
      	</g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      </svg>

    );
  }
}
