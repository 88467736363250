import React, { Component } from 'react';
import throttle from 'lodash.throttle';
import { Link } from 'gatsby';
import { toTop as scrollToTop } from '../../utils/scroll';

// Components
import MobileMenuButton from '../../components/MobileMenuButton';
import GetStartedButton from '../../components/GetStartedButton';

// Assets
import BlueLogo from '../../images/dark_logo_transparent.png';

// Styles
import './dropdownNav.styles.scss';

export default ChildComponent => {
    class DropdownNav extends Component {
        constructor(props) {
            super(props);

            this.state = {
                scrollY: 0,
                navBottom: null,
                navHeight: null,
                isLandingInView: true,
                isDropdownVisible: false
            };

            this.throttledScrollHandler = throttle(this.handleScroll, 250);
        }

        componentDidMount() {
            window.addEventListener(
                'scroll',
                this.throttledScrollHandler,
                true
            );
        }

        componentWillUnmount() {
            window.removeEventListener(
                'scroll',
                this.throttledScrollHandler,
                true
            );
        }

        handleScroll = () => {
            this.setState({
                scrollY: window.scrollY,
                navBottom: (
                    !this.state.isLandingInView ? 
                    this.nav.offsetHeight : this.state.navBottom
                )
            });
        }

        showDropdown = () => {
            if (!this.state.isDropdownVisible) {
                this.setState({
                    isDropdownVisible: true,
                    isLandingInView: false
                });
            }
        }

        hideDropdown = () => {
            if (this.state.isDropdownVisible) {
                this.setState({
                    isLandingInView: true,
                    isDropdownVisible: false
                });
            }
        }

        handleHomeClick = () => {
            scrollToTop();
        };

        render() {
            const { isDropdownVisible } = this.state;

            return (
                <div className="composed-component">
                    <div className={
                        `dd-nav-container ${
                            isDropdownVisible ?
                            'isVisible' : 'isHidden'
                        }`
                    }>
                        <nav
                            className="dd-nav"
                            ref={(elem) => this.nav = elem}
                        >
                            <div className="dd-nav__left">
                                <div 
                                    className="dd-nav__logo"
                                    onClick={this.handleHomeClick}
                                >
                                    <img src={BlueLogo} alt="Transferable logo" />
                                </div>
                            </div>
      
                            <div className="dd-nav__right">
                                <ul className="dd-nav__list">
                                    <li
                                        className="dd-nav__list-item"                    
                                        onClick={this.handleHomeClick}
                                    >
                                        Home
                                    </li>
                                    <li className="dd-nav__list-item">
                                        <Link to="/about">
                                            About
                                        </Link>
                                    </li>
                                    <li className="dd-nav__list-item">
                                        <Link to="/public-benefit">
                                            Public Benefit
                                        </Link>
                                    </li> 
                                    <li className="dd-nav__list-item">
                                        <Link to="/blog">
                                            Blog
                                        </Link>
                                    </li>
                                    <li className="dd-nav__list-item">
                                        <Link to="/resources">
                                            Resources
                                        </Link>
                                    </li>
                                    <li className="dd-nav__list-item">
                                        <Link to="/contact">
                                            Contact us
                                        </Link>
                                    </li>
                                    <li className="dd-nav__list-item">
                                        <GetStartedButton />
                                    </li>
      
                                    <li className="dd-nav__list-item mobile-menu-btn">
                                        <MobileMenuButton />
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
      
                    <div className="child-component-container">
                        <ChildComponent
                            {...this.props}
                            showDropdown={this.showDropdown}
                            hideDropdown={this.hideDropdown}
                            scrollY={this.state.scrollY}
                            navBottom={this.state.navBottom}
                        />
                    </div>
              </div>
            );
        }
    }

    return DropdownNav;
};
