import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import PersonIcon from '../PersonIcon';

import './Problem.styles.scss';

export default class Problem extends Component {

  render() {
    const svgDimensions = {
      mobile: {
        width: '1.25em',
        height: '1.5em'
      },
      tabletUp: {
        width: '1.675em',
        height: '2em'
      }
    };

    return (
      <div className="problem">
        <div className="problem__body">
          <Fade clear>
            <div className="problem__header">
              <h2 className="problem__title">
                {'Many Students\''}<br/>
                 {'Transfer Expectations'}<br/>
                 {'Are Not Met'}
              </h2>

              <div className="problem__header-img-container">
                <div className="problem__header-img" />
              </div>
            </div>
          </Fade>

          <div className="problem__stats">
            <div className="problem__stats-wrapper">

              <Fade clear>
                <div className="problem__stat stat-1">
                  <div className="problem__stat-text">
                    <span className="problem__stat-percentage stat-1-percentage">
                      {'80% '}
                    </span>
                    <span className="problem__stat-blurb stat-2-blurb">
                      of students intend to earn a bachelor's degree.
                    </span>
                  </div>

                  <div className="problem__stat-infographic">
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="#ff8c00"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="#ff8c00"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="#ff8c00"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="#ff8c00"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="#ff8c00"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="#ff8c00"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="#ff8c00"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="#ff8c00"
                    />
                  </div>
                </div>
              </Fade>

              <Fade clear>
                <div className="problem__stat stat-2">
                  <div className="problem__stat-text">
                    <span className="problem__stat-percentage stat-2-percentage">
                      {'25% '}
                    </span>
                    <span className="problem__stat-blurb stat-2-blurb">
                      of students transfer to a 4-year college within 5 years.
                    </span>
                  </div>

                  <div className="problem__stat-infographic">
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="rgb(200, 20, 50)"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="rgb(200, 20, 50)"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="rgb(200, 20, 50)"
                    />
                  </div>
                </div>
              </Fade>

              <Fade clear>
                <div className="problem__stat stat-3">
                  <div className="problem__stat-text">
                    <span className="problem__stat-percentage stat-3-percentage">
                      {'17% '}
                    </span>

                    <span className="problem__stat-blurb stat-2-blurb">
                      of students earn a bachelor's within 6 years of transferring.
                    </span>
                  </div>

                  <div className="problem__stat-infographic">
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="grey"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="rgb(68, 87, 174)"
                    />
                    <PersonIcon
                      width={svgDimensions.tabletUp.width}
                      height="2rem"
                      color="rgb(68, 87, 174)"
                    />
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
