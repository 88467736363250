import React, { Component} from 'react';

export default class DiplomaIcon extends Component {
  render() {

    const { width, height, color } = this.props;
    
    return (
      <svg fill="#001533" style={{ backgroundColor: 'transparent' }} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      	viewBox="0 0 60 60" xmlSpace="preserve"
        width={width}
        height={height}
        fill={color}
      >
      <g>
      	<path d="M38.914,0H6.5v60h47V14.586L38.914,0z M39.5,3.414L50.086,14H39.5V3.414z M8.5,58V2h29v14h14v42H8.5z"/>
      	<path d="M16.5,17h9c0.553,0,1-0.447,1-1s-0.447-1-1-1h-9c-0.553,0-1,0.447-1,1S15.947,17,16.5,17z"/>
      	<path d="M16.5,25h5c0.553,0,1-0.447,1-1s-0.447-1-1-1h-5c-0.553,0-1,0.447-1,1S15.947,25,16.5,25z"/>
      	<path d="M31.5,25h7c0.553,0,1-0.447,1-1s-0.447-1-1-1h-7c-0.553,0-1,0.447-1,1S30.947,25,31.5,25z"/>
      	<path d="M25.79,23.3c-0.19,0.18-0.29,0.439-0.29,0.7c0,0.26,0.1,0.52,0.29,0.71C25.979,24.89,26.24,25,26.5,25s0.52-0.101,0.71-0.3
      		c0.18-0.181,0.29-0.44,0.29-0.7c0-0.261-0.11-0.521-0.29-0.71C26.83,22.92,26.16,22.92,25.79,23.3z"/>
      	<path d="M43.5,25c0.26,0,0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71c0-0.261-0.11-0.521-0.29-0.71
      		c-0.38-0.37-1.04-0.37-1.42,0c-0.181,0.189-0.29,0.449-0.29,0.71c0,0.26,0.109,0.52,0.29,0.71C42.979,24.89,43.24,25,43.5,25z"/>
      	<path d="M28.5,31h-7c-0.553,0-1,0.447-1,1s0.447,1,1,1h7c0.553,0,1-0.447,1-1S29.053,31,28.5,31z"/>
      	<path d="M16.5,33c0.26,0,0.52-0.11,0.71-0.29c0.189-0.19,0.29-0.45,0.29-0.71c0-0.261-0.101-0.521-0.29-0.71
      		c-0.38-0.37-1.04-0.37-1.42,0C15.6,31.479,15.5,31.729,15.5,32c0,0.26,0.109,0.52,0.29,0.71C15.979,32.89,16.24,33,16.5,33z"/>
      	<path d="M19.645,40.166c-2.19,1.14-2.927,3.321-3.196,5.582c-0.414-0.347-0.828-0.693-1.242-1.04
      		c-0.98-0.821-2.402,0.586-1.414,1.415c0.935,0.783,1.871,1.567,2.806,2.351c0.658,0.551,1.677,0.203,1.707-0.707
      		c0.073-2.166,0.175-4.742,2.348-5.873C21.796,41.299,20.786,39.572,19.645,40.166z"/>
      	<path d="M26.295,44.155c-1.385,0.321-2.961,2.083-3.936,0.106c-0.569-1.154-2.295-0.142-1.727,1.009
      		c0.517,1.049,1.5,1.784,2.669,1.932c0.594,0.075,1.131-0.009,1.688-0.226c0.409-0.159,1.937-1.308,2.267-0.717
      		c0.628,1.124,2.356,0.116,1.727-1.009C28.446,44.288,27.359,43.908,26.295,44.155z"/>
      	<path d="M46.745,34.513c-0.233-0.083-0.336-0.259-0.377-0.357c-0.04-0.098-0.093-0.296,0.014-0.52c0.485-1.022,0.282-2.199-0.518-3
      		c-0.801-0.799-1.979-1.003-2.999-0.518c-0.226,0.107-0.424,0.054-0.521,0.014c-0.099-0.041-0.274-0.144-0.357-0.377
      		C41.608,28.688,40.632,28,39.5,28s-2.108,0.688-2.487,1.755c-0.083,0.233-0.259,0.336-0.357,0.377
      		c-0.097,0.04-0.296,0.094-0.52-0.014c-1.021-0.483-2.198-0.282-3,0.518c-0.8,0.801-1.003,1.978-0.518,2.999
      		c0.106,0.225,0.054,0.423,0.014,0.521c-0.041,0.099-0.144,0.274-0.377,0.357C31.188,34.892,30.5,35.868,30.5,37
      		s0.688,2.108,1.755,2.487c0.233,0.083,0.336,0.259,0.377,0.357c0.04,0.098,0.093,0.296-0.014,0.52
      		c-0.485,1.022-0.282,2.199,0.518,3c0.387,0.386,0.862,0.63,1.364,0.728v12.322l5-5l5,5V44.091c0.502-0.097,0.978-0.34,1.364-0.727
      		c0.8-0.801,1.003-1.978,0.518-2.999c-0.106-0.225-0.054-0.423-0.014-0.521c0.041-0.099,0.144-0.274,0.377-0.357
      		C47.812,39.108,48.5,38.132,48.5,37S47.812,34.892,46.745,34.513z M39.5,48.586l-3,3v-7.758c0.009,0.001,0.018,0.002,0.026,0.004
      		c0.05,0.01,0.098,0.022,0.13,0.036c0.049,0.021,0.118,0.057,0.184,0.116c0.067,0.06,0.132,0.144,0.173,0.261
      		C37.392,45.312,38.368,46,39.5,46s2.108-0.688,2.487-1.755c0.041-0.117,0.106-0.201,0.173-0.261
      		c0.067-0.06,0.135-0.096,0.184-0.116c0.032-0.013,0.079-0.026,0.129-0.036c0.008-0.001,0.017-0.003,0.026-0.004v7.757L39.5,48.586z
      		 M46.075,37.603c-0.702,0.249-1.269,0.787-1.554,1.476c-0.286,0.689-0.267,1.471,0.054,2.146c0.175,0.367-0.029,0.63-0.126,0.726
      		c-0.079,0.081-0.281,0.224-0.561,0.17l-0.22-0.069c-0.091-0.041-0.187-0.063-0.281-0.092c-0.07-0.022-0.138-0.052-0.209-0.069
      		c-0.098-0.022-0.199-0.028-0.299-0.039c-0.081-0.009-0.16-0.026-0.241-0.028c-0.097-0.002-0.193,0.014-0.289,0.022
      		c-0.083,0.008-0.167,0.006-0.25,0.022c-0.177,0.033-0.351,0.084-0.52,0.154c0,0-0.001,0-0.002,0.001h0
      		c-0.044,0.018-0.081,0.047-0.124,0.067c-0.124,0.06-0.249,0.119-0.362,0.197c-0.135,0.093-0.258,0.203-0.373,0.319
      		c-0.013,0.013-0.03,0.023-0.043,0.037c-0.252,0.265-0.449,0.583-0.574,0.934C39.967,43.959,39.636,44,39.5,44
      		s-0.467-0.041-0.603-0.425c-0.125-0.352-0.322-0.669-0.574-0.934c-0.01-0.011-0.023-0.018-0.034-0.029
      		c-0.118-0.12-0.244-0.231-0.383-0.327c-0.108-0.075-0.229-0.131-0.348-0.189c-0.047-0.023-0.089-0.055-0.138-0.075h0
      		c-0.001,0-0.001-0.001-0.001-0.001c-0.021-0.009-0.043-0.011-0.064-0.019c-0.146-0.056-0.294-0.103-0.445-0.132
      		c-0.122-0.024-0.243-0.029-0.365-0.036c-0.045-0.002-0.09-0.013-0.135-0.013c-0.003,0-0.005,0.001-0.008,0.001
      		c-0.374-0.001-0.737,0.072-1.047,0.223l-0.227,0.071c-0.287,0.065-0.496-0.083-0.578-0.166c-0.097-0.096-0.301-0.358-0.126-0.727
      		c0.32-0.674,0.34-1.455,0.054-2.145c-0.285-0.688-0.852-1.227-1.554-1.476C32.541,37.467,32.5,37.136,32.5,37
      		s0.041-0.467,0.425-0.603c0.702-0.249,1.269-0.787,1.554-1.476c0.286-0.689,0.267-1.471-0.054-2.146
      		c-0.175-0.367,0.029-0.63,0.126-0.726c0.096-0.097,0.357-0.299,0.727-0.126c0.673,0.32,1.455,0.342,2.145,0.054
      		c0.688-0.285,1.227-0.852,1.476-1.554C39.033,30.041,39.364,30,39.5,30s0.467,0.041,0.603,0.425
      		c0.25,0.702,0.787,1.269,1.476,1.554c0.688,0.288,1.471,0.267,2.146-0.054c0.366-0.177,0.631,0.029,0.726,0.126
      		c0.097,0.096,0.301,0.358,0.126,0.727c-0.32,0.674-0.34,1.455-0.054,2.145c0.285,0.688,0.852,1.227,1.554,1.476
      		C46.459,36.533,46.5,36.864,46.5,37S46.459,37.467,46.075,37.603z"/>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      </svg>

    );
  }
}
